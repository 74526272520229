import LinesParticles from "../widgets/Particles";
import "./layout.css";

const AuthWrapper = ({ children }: { children: any }) => {
  return (
    <div className="auth_wrapper">
      <LinesParticles />
      {children}
    </div>
  );
};

export default AuthWrapper;
