import { END_POINTS } from "../helpers";
import { CommonBody, EventBooking } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: {
    count: number;
    booking: EventBooking[] | [];
  };
  message: string;
};

type EditParams = {
  body: CommonBody;
  bookingId: string;
};

export const eventBookingApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEventBookings: builder.query<
      CommonResponseType,
      {
        page: number;
        query: string;
        size: number;
        activeTab: number;
        eventId: string | undefined;
      }
    >({
      query: ({ page, query, size, activeTab, eventId }) => ({
        url: `${
          END_POINTS.eventBooking
        }?page=${page}&size=${size}&search=${query}&all=false&type=${activeTab}${
          eventId ? `&eventId=${eventId}` : ""
        }`,
        method: "GET",
      }),
    }),

    getEventBookingById: builder.query<
      CommonResponseType & { data: EventBooking },
      { eventId: string }
    >({
      query: ({ eventId }) => ({
        url: `${END_POINTS.eventBooking}/${eventId}`,
        method: "GET",
      }),
    }),

    editBookingStatus: builder.mutation<CommonResponseType, EditParams>({
      query: ({ body, bookingId }) => ({
        url: `${END_POINTS.cancelBooking}/${bookingId}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAllEventBookingsQuery,
  useLazyGetEventBookingByIdQuery,
  useEditBookingStatusMutation,
} = eventBookingApi;
