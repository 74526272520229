import { Button, Col, Input, Row, Select, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EventBooking, Events } from "../../types/General";
import moment from "moment";

interface DataType {
  key: string;
  eventName: string;
  customerName: string;
  location: string;
  eventDateTime: string;
  purchasedTicket: number;
  ticketCategory: string;
}

const { Option } = Select;

const ActiveBookings = ({
  eventsList,
  setSelectedEvent,
  selectedEvent,
  setQuery,
  currentPage,
  totalCount,
  setCurrentPage,
  loading,
  setLoading,
  getBookingDetail,
  bookingDetail,
}: {
  eventsList: Events[];
  setSelectedEvent: Dispatch<SetStateAction<string | undefined>>;
  selectedEvent: string | undefined;
  setQuery: Dispatch<SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalCount: number | undefined;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  getBookingDetail: (page: number) => void;
  bookingDetail: EventBooking[] | undefined;
}) => {
  const navigate = useNavigate();

  const [debouncedQuery, setDebouncedQuery] = useState("");

  const columns: ColumnsType<EventBooking> = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Purchased Tickets",
      dataIndex: "noOfTickets",
      key: "noOfTickets",
      align: "center",
    },
    {
      title: "Ticket Category",
      dataIndex: "ticketCategoryName",
      key: "ticketCategoryName",
      align: "center",
    },
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      align: "center",
      render: (_, { event }) => (
        <div>
          <span>{event?.name || ""}</span>
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      align: "center",
      render: (_, { event }) => (
        <div>
          <span>{event?.address || ""}</span>
        </div>
      ),
    },

    {
      title: "Event Date Time",
      dataIndex: "eventDateTime",
      key: "eventDateTime",
      align: "center",
      render: (_, { event }) => (
        <div>
          <span>{moment(event?.time).format("MMM Do YYYY, h:mm a") || ""}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-event-booking/details/${record?._id}`, {
                state: {
                  type: "active",
                },
              });
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      eventName: "Meeting",
      customerName: "John Charles",
      location: "Pune",
      eventDateTime: "20 Sep, 10:00 pm",
      purchasedTicket: 10,
      ticketCategory: "Basic",
    },
    {
      key: "2",
      eventName: "Meeting",
      customerName: "Emma Watson",
      location: "Chandigarh",
      eventDateTime: "20 Jan,2:00 am",
      purchasedTicket: 12,
      ticketCategory: "VIP",
    },
    {
      key: "3",
      eventName: "Meeting",
      customerName: "Ria",
      location: "New Delhi",
      eventDateTime: "10 May,12:00 pm",
      purchasedTicket: 2,
      ticketCategory: "Gold",
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getBookingDetail(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  return (
    <div>
      <Row style={{ padding: "10px 0px" }} gutter={16}>
        <Col xs={24} md={7}>
          <Input
            placeholder="Search Bookings"
            prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
            style={{ height: 50, borderRadius: 10 }}
            value={debouncedQuery}
            onChange={(e) => setDebouncedQuery(e.target.value)}
            allowClear
          />
        </Col>
        <Col xs={24} md={7}>
          <Select
            allowClear
            maxLength={20}
            className="select_type withInput"
            onChange={(val) => setSelectedEvent(val)}
            value={selectedEvent}
            placeholder="Select Event"
          >
            {eventsList?.length
              ? eventsList?.map((item, index) => (
                  <Option key={index} value={item?._id} label={item?.name}>
                    {item?.name}
                  </Option>
                ))
              : undefined}
          </Select>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={bookingDetail}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

export default ActiveBookings;
