import { CloseCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import ProductImages from "../../../components/events/ProductImages";
import { Dispatch, SetStateAction } from "react";
import { RcFile } from "antd/es/upload";

type ManageProps = {
  imagePreview: string[];
  setImagePreview: Dispatch<SetStateAction<string[]>>;
  imageObject: RcFile[] | number[] | any;
  setImageObject: Dispatch<SetStateAction<RcFile[] | number[]>>;
  handleImageDelete: (arg0: number) => void;
  handleGetImageDelete: (arg0: number) => void;
  getImages: never[];
};

const ImageUploadForm = ({
  imagePreview,
  setImageObject,
  imageObject,
  setImagePreview,
  handleImageDelete,
  getImages,
  handleGetImageDelete,
}: ManageProps) => {
  return (
    <Card>
      <h3>Images, and videos for the event</h3>
      <Row gutter={16}>
        <Col xs={24} md={24}>
          <Row gutter={16}>
            {getImages?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleGetImageDelete(index)}
                className="img_contnr"
              >
                <CloseCircleOutlined
                  style={{
                    color: "red",
                    position: "absolute",
                    right: 4,
                    top: 4,
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                />
                {item ? (
                  <figure>
                    {/* @ts-ignore */}
                    {item?.split(".").pop() === "mp4" ? (
                      <video muted src={item} autoPlay />
                    ) : (
                      <img alt="" src={item} />
                    )}
                  </figure>
                ) : null}
              </div>
            ))}
            {imagePreview?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleImageDelete(index)}
                className="img_contnr"
              >
                <CloseCircleOutlined
                  style={{
                    color: "red",
                    position: "absolute",
                    right: 4,
                    top: 4,
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                />
                <figure>
                  {imageObject[index].type.includes("video") ? (
                    <video muted src={item} autoPlay />
                  ) : (
                    <img alt="" src={item} />
                  )}
                </figure>
              </div>
            ))}
          </Row>
          <div className="img_contnr2">
            <ProductImages
              imageObject={imageObject}
              setImageObject={setImageObject}
              imagePreview={imagePreview}
              setImagePreview={setImagePreview}
              accept={"image/png, image/gif, image/jpeg, image/jpg, video/*"}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default ImageUploadForm;
