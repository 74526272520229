import { Card, Select } from "antd";
import { LineChart } from ".";

const EarningGraph = (props: any) => {
  return (
    <div style={{ padding: 0 }}>
      <Card className="chart_card">
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2 style={{ margin: 0, fontSize: 19 }}>Total Earnings</h2>
          <div style={{ width: "20%" }}>
            <Select
              style={{ width: "100%" }}
              onChange={(val) => props.setEarningsFilter(val)}
              value={props.earningsFilter}
              placeholder="Filter"
              options={[
                { value: "daily", label: "Daily" },
                { value: "weekly", label: "Weekly" },
                { value: "monthly", label: "Monthly" },
                { value: "yearly", label: "Yearly" },
              ]}
            />
          </div>
        </div>
        <div style={{ margin: "auto" }}>
          <LineChart data={props.graphCustomerData} />
        </div>
      </Card>
    </div>
  );
};

export default EarningGraph;
