import { Button, Card, Col, Form, Input, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../../components";
import {
  useAddEmployeeMutation,
  useLazyGetEmployeeByIdQuery,
  usePutUpdateEmployeeMutation,
} from "../../../services/employees";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { errorToast, successToast } from "../../../helpers";
import { UploadMedia } from "../../../utils/mediaUpload";
import Upload, { RcFile } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
import { isString } from "../../../utils/validations";
import { Autocomplete } from "@react-google-maps/api";

const UsersForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { view } = useParams();

  const url = window.location.pathname;
  const path = url.includes("add");
  const [addEmployee] = useAddEmployeeMutation();
  const [getEmployeeDetailById] = useLazyGetEmployeeByIdQuery();
  const [updateEmployee] = usePutUpdateEmployeeMutation();

  const [employeeImage, setEmployeeImage] = useState<string | null>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [countryName, setCountryName] = useState("us");
  const [phoneCode, setPhoneCode] = useState("+1");
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const onKeyPressHandler = (e: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const onLoad = (autocompleteObj: SetStateAction<null>) => {
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = async (e: any, val: any) => {
    if (autocomplete) {
      // @ts-ignore
      var place = await autocomplete.getPlace();

      if (place) {
        form.setFieldsValue({
          address: place.formatted_address,
        });

        setAddress(place.formatted_address);

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLat(lat);
        setLong(lng);
      }
    }
  };

  const onFinish = async (values: any) => {
    setError(false);

    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 6) {
      setError(true);
      return;
    }

    let data = {
      image: employeeImage,
      firstName: values?.firstname,
      lastName: values.lastname,
      email: values?.email,
      phone: phoneNo,
      address: values?.address,
      password: values?.password,
      countryCode: phoneCode?.includes("+") ? phoneCode : `+${phoneCode}`,
      countryName: countryName,
      latitude: lat?.toString() || "76.804461",
      longitude: long?.toString() || "30.727297",
    };

    console.log(data, "datatttttttaaaaaa");

    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    setLoading(true);

    try {
      let res;
      {
        path
          ? (res = await addEmployee(encryptedBody).unwrap())
          : (res = await updateEmployee({
              encryptedBody,
              employeeId: view ? view : "",
            }).unwrap());

        setLoading(false);
        if (res?.statusCode === 200) {
          successToast(
            path
              ? "Employee Added Successfully"
              : "Employee Updated Successfully"
          );
          navigate("/manage-employee", { replace: true });
        }
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const validateConfirmPassword = ({
    getFieldValue,
  }: {
    getFieldValue: any;
  }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Passwords do not match."));
    },
  });

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setEmployeeImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const getEmployeeById = async () => {
    try {
      const res = await getEmployeeDetailById({ employeeId: view }).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          image: res?.data?.image,
          firstname: res?.data?.firstName,
          lastname: res?.data?.lastName,
          email: res?.data?.email,
          phone: res?.data?.phone,
          address: res?.data?.address,
        });
        setFirstName(res?.data?.firstName);
        setEmployeeImage(res?.data?.image);
        setAddress(res?.data?.address);
        setCountryName(res?.data?.countryName);
        setPhoneCode(res?.data?.countryCode);
        setEmail(res?.data?.email);
        setPhoneNo(res?.data?.phone);
        setLastName(res?.data?.lastName);
        setPhoneCode(res?.data?.countryCode);
        if (res?.data?.location?.coordinates?.length) {
          setLat(res?.data?.latitude || "");
          setLong(res?.data?.longitude || "");
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (path) {
    } else {
      getEmployeeById();
    }
  }, []);

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn">
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manage-employee"
        heading={path ? "Add Employee" : "Edit Employee"}
      />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "This field is compulsory",
                    },
                  ]}
                  name="image"
                >
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {employeeImage ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={employeeImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                      whitespace: true,
                    },
                    {
                      min: 2,
                      message: "Name must be at least 2 characters",
                    },
                    {
                      max: 20,
                      message: "Name must be at most 20 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>First Name</h4>
                    <Input
                      placeholder="First Name"
                      style={{ height: 55 }}
                      value={firstName}
                      onChange={(e) => {
                        if (isString(e.target.value)) {
                          setFirstName(e.target.value);
                        }
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="lastname"
                  rules={[
                    {
                      min: 2,
                      message: "Name must be at least 2 characters",
                    },
                    {
                      max: 20,
                      message: "Name must be at most 20 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Last Name</h4>
                    <Input
                      placeholder="Last Name"
                      style={{ height: 55 }}
                      value={lastName}
                      onChange={(e) => {
                        if (isString(e.target.value)) {
                          setLastName(e.target.value);
                        }
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      // disabled
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        country={"us"}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                  </div>
                  {error && !phoneNo ? (
                    <h3 className="error_msg">
                      Please input your phone number!
                    </h3>
                  ) : null}
                  {error && phoneNo?.length && phoneNo?.length < 6 ? (
                    <h3 className="error_msg">
                      Please input valid phone number!
                    </h3>
                  ) : null}
                </Form.Item>
              </Col>
            </Row>

            {path ? (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="password"
                    className="pswrd"
                    rules={[
                      { required: true, message: "Please add password!" },
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message:
                          "Must contain 8 or more characters, one uppercase, one lowercase, one special character and one number.",
                      },
                    ]}
                  >
                    <div>
                      <h4>Password</h4>
                      <Input.Password
                        style={{ height: 55 }}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        value={password}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      validateConfirmPassword,
                      // {
                      //   validator: handleConfirmPassword,
                      // },
                    ]}
                  >
                    <div>
                      <h4>Confirm Password</h4>
                      <Input.Password
                        style={{ height: 55 }}
                        placeholder="Confirm Password"
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            ) : undefined}

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Address!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Address must be at least 3 characters",
                    },
                  ]}
                >
                  {/* @ts-ignore */}
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <div>
                      <h4>Address</h4>
                      <Input
                        placeholder="Address"
                        style={{ height: 55 }}
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        onKeyDown={onKeyPressHandler}
                        // allowClear
                      />
                    </div>
                  </Autocomplete>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => setError(true)}
                >
                  {path ? "Add" : "Update"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default UsersForm;
