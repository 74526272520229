import { Button, Card, Form, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../../components";
import { RcFile } from "antd/es/upload";
import EventDetailsForm from "./EventDetailsForm";
import SocialLinkForm from "./SocialLinksForm";
import ImageUploadForm from "./ImageUploadForm";
import EventRulesForm from "./EventRulesForm";
import TicketsForm from "./TicketsForm";
import { errorToast, successToast } from "../../../helpers";
import { UploadMedia } from "../../../utils/mediaUpload";
import {
  useAddEventsMutation,
  useEditEventsMutation,
  useLazyGetEventByIdQuery,
  useLazyGetEventsCategoryQuery,
} from "../../../services/events";
import { CommonBody, EventCategory, Events } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import React from "react";

const CatOptions = [{ name: "Launch Party" }, { name: "Success Party" }];

interface tickets {
  count: string;
  category: string;
  price: string;
  _id?: number | undefined;
}

const EventsForm = () => {
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { view } = useParams();

  const [imageObject, setImageObject] = useState<RcFile[] | number[]>([]);
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [ticketsValue, setTicketsValue] = useState<tickets[]>([]);
  const [loading, setLoading] = useState(false);
  const [ticketCat, setTicketCat] = useState("");
  const [ticketCount, setTicketCount] = useState("");
  const [ticketPrice, setTicketPrice] = useState("");
  const [eventValues, setEventValues] = useState({
    eventName: "",
    eventDescription: "",
    // hostName: "",
    location: "",
  });
  const [linksValues, setLinksValues] = useState({
    fbLink: "",
    instaLink: "",
    twitterLink: "",
  });
  const [getImages, setGetImages] = useState([]);
  const [vendorRule, setVendorRule] = useState(view === "add" ? " " : "");
  const [bookingRule, setBookingRule] = useState(view === "add" ? " " : "");
  const [cancelRule, setCancelRule] = useState(view === "add" ? " " : "");
  const [eventCategory, setEventCategory] = useState<EventCategory[]>([]);
  const [startDateTime, setStartDateTime] = useState<dayjs.Dayjs | null>(null);
  const [endDateTime, setEndDateTime] = useState<dayjs.Dayjs | null>(null);
  const [error, setError] = useState(false);
  const [eventDetail, setEventDetail] = useState<Events>();
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const [eventCategoryMethod] = useLazyGetEventsCategoryQuery();
  const [addEventsMethod] = useAddEventsMutation();
  const [editEventsMethod] = useEditEventsMutation();
  const [eventByIdMethod] = useLazyGetEventByIdQuery();

  const handleImageUpload = async (file: RcFile) => {
    setLoading(true);
    try {
      const res = await UploadMedia(file);
      setLoading(false);
      if (res?.statusCode === 200) {
        return res?.data;
      }
      return 0;
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      return 0;
    }
  };

  const onFinish = async (values: any) => {
    setError(false);
    let newArr = ticketsValue as tickets[];
    if (ticketCat && ticketCount && ticketPrice) {
      newArr.push({
        price: ticketPrice,
        count: ticketCount,
        category: ticketCat,
        _id: ticketsValue?.length + 1,
      });
      setTicketCat("");
      setTicketCount("");
      setTicketPrice("");
    }
    const newArray = newArr.map((obj) => {
      const { ["_id"]: _, ...rest } = obj;
      return rest;
    });

    if (!ticketsValue?.length && !ticketCat && !ticketCount) {
      setError(true);
      errorToast("Add Ticket Details first");
      return;
    }
    if (!selectedCategory || !startDateTime || !endDateTime) {
      setError(true);
      return;
    }
    if (!imageObject?.length && !getImages?.length) {
      // setImageError(true);
      errorToast("Please provide images/videos for event.");

      return;
    }

    let images = [] as any;
    let urls = [] as any;
    if (imageObject?.length) {
      await Promise.all(
        imageObject?.map(async (item) => {
          if (typeof item !== "number") {
            let result = await handleImageUpload(item);
            console.log(result);
            images?.push({
              image: result,
            });
          } else {
            images?.push({
              image: item,
            });
          }
        })
      );
      urls = images.map((item: any) => item.image);
    }
    let data = {
      // hostName: values.hostName,
      name: values.eventName,
      description: values.eventDescription,
      images: Array.from(getImages).concat(urls),
      time: startDateTime?.toISOString(),
      endTime: endDateTime?.toISOString(),
      eventCategoryId: selectedCategory,
      address: values.location,
      latitude: lat?.toString() || "76.804461",
      longitude: long?.toString() || "30.727297",
      ticket: newArray,
      facebook: linksValues.fbLink,
      instagram: linksValues.instaLink,
      twitter: linksValues.twitterLink,
      vendorRules: vendorRule,
      bookingRules: bookingRule,
      cancellationRules: cancelRule,
    };

    console.log(data, "bodyyy");

    const body = generateEncryptedKeyBody(data) as CommonBody;
    setLoading(true);
    try {
      let res;
      {
        view === "add"
          ? (res = await addEventsMethod(body).unwrap())
          : (res = await editEventsMethod({
              body,
              eventId: view ? view : "",
            }).unwrap());
      }
      setLoading(false);
      if (res?.statusCode === 200) {
        successToast(
          view === "add"
            ? "Event Added Successfully"
            : "Event Updated Successfully"
        );
        navigate("/manage-events", { replace: true });
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleImageDelete = (index: number) => {
    let previewImages = imagePreview.filter((_, i) => i !== index);
    setImagePreview(previewImages);
    let objectImages = (imageObject as any).filter(
      (_: any, i: number) => i !== index
    );
    setImageObject(objectImages as any);
  };

  const handleGetImageDelete = (index: number) => {
    let previewImages = getImages.filter((_, i) => i !== index);
    setGetImages(previewImages);
  };

  const getEventCategoryData = async () => {
    try {
      setLoading(true);
      const res = await eventCategoryMethod({}).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setEventCategory(res?.data?.eventCategory || []);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const getEventDetailData = async () => {
    try {
      setLoading(true);
      if (view) {
        const res = await eventByIdMethod({ eventId: view }).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          setEventDetail(res?.data);
          setVendorRule(res?.data?.vendorRules);
          setBookingRule(res?.data?.bookingRules);
          setCancelRule(res?.data?.cancellationRules);
          setSelectedCategory(res?.data?.eventCategory?._id);
          setStartDateTime(dayjs(dayjs(res?.data?.time)));
          setEndDateTime(dayjs(dayjs(res?.data?.endTime)));
          setTicketsValue(res?.data?.ticket);
          if (res?.data?.location?.coordinates?.length) {
            setLat(res?.data?.location?.coordinates[0] || "");
            setLong(res?.data?.location?.coordinates[1] || "");
          }
          setEventValues({
            ...eventValues,
            eventName: res?.data?.name,
            eventDescription: res?.data?.description,
            // hostName: res?.data?.hostName,
            location: res?.data?.address,
          });
          setLinksValues({
            ...linksValues,
            fbLink: res?.data?.facebook,
            twitterLink: res?.data?.twitter,
            instaLink: res?.data?.instagram,
          });
          form.setFieldsValue({
            eventName: res?.data?.name,
            eventDescription: res?.data?.description,
            // hostName: res?.data?.hostName,
            location: res?.data?.address,
          });
          setGetImages(res?.data?.images);
        }
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getEventCategoryData();
    if (view === "add") {
    } else {
      getEventDetailData();
    }
  }, []);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     location: eventValues.location,
  //   });
  // }, [eventValues.location]);

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn frminput edteventform">
      <Spin spinning={loading}>
        <ContentHeader
          buttonText="Back"
          buttonNavigateTo="manage-events"
          heading={view === "add" ? "Add Events" : "Edit Events"}
        />
        <Card>
          <div>
            <Form
              name="basic"
              layout="vertical"
              form={form}
              style={{
                maxWidth: "100%",
                marginTop: 10,
                gap: 20,
                display: "grid",
              }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <EventDetailsForm
                CatOptions={CatOptions}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setEventValues={setEventValues}
                eventValues={eventValues}
                eventCategory={eventCategory}
                setStartDateTime={setStartDateTime}
                startDateTime={startDateTime}
                setEndDateTime={setEndDateTime}
                endDateTime={endDateTime}
                error={error}
                setLat={setLat}
                setLong={setLong}
                form={form}
              />

              <TicketsForm
                ticketsValue={ticketsValue}
                setTicketsValue={setTicketsValue}
                ticketCat={ticketCat}
                setTicketCat={setTicketCat}
                setTicketCount={setTicketCount}
                ticketCount={ticketCount}
                setTicketPrice={setTicketPrice}
                ticketPrice={ticketPrice}
              />

              <SocialLinkForm
                setLinksValues={setLinksValues}
                linksValues={linksValues}
              />

              <ImageUploadForm
                handleImageDelete={handleImageDelete}
                handleGetImageDelete={handleGetImageDelete}
                imagePreview={imagePreview}
                setImagePreview={setImagePreview}
                imageObject={imageObject}
                setImageObject={setImageObject}
                getImages={getImages}
              />

              <EventRulesForm
                setVendorRule={setVendorRule}
                vendorRule={vendorRule}
                bookingRule={bookingRule}
                setBookingRule={setBookingRule}
                cancelRule={cancelRule}
                setCancelRule={setCancelRule}
                error={error}
                eventDetail={eventDetail}
              />

              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <div style={{ display: "flex" }}>
                  <Button
                    style={{
                      background: "#ECAC35",
                      width: 200,
                      height: 50,
                      margin: "auto",
                      color: "black",
                    }}
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      setError(true);
                    }}
                  >
                    {view === "add" ? "Add" : "Update"}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default EventsForm;
