import { Button, Col, Modal, Row, Select, Space, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EyeOutlined, EditFilled, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface DataType {
  key: string;
  bankName: string;
  holderEmail: string;
  IfscCode: string;
  accountHolderName: string;
  status: boolean;
  events: {
    id: string;
    name: string;
  }[];
}

const { confirm } = Modal;
const { Option } = Select;

const eventNames = [{ name: "Launch Party" }, { name: "Success Party" }];

const BankDetails = () => {
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState<string>();

  const renderSwitch = (status: any) => (
    <Switch size="small" defaultChecked={status} />
  );

  const showDeleteConfirm = () => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this detail?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      align: "center",
    },
    {
      title: "IFSC/SWIFT Code",
      dataIndex: "IfscCode",
      key: "IfscCode",
      align: "center",
    },
    {
      title: "Account Holder Email",
      dataIndex: "holderEmail",
      key: "holderEmail",
      align: "center",
    },
    {
      title: "Account Holder Name",
      dataIndex: "accountHolderName",
      key: "accountHolderName",
      align: "center",
    },
    {
      title: "Select Event",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Space size="middle">
          <Select
            allowClear
            maxLength={20}
            className="select_type withInput_small"
            onChange={(val) => setSelectedEvent(val)}
            value={selectedEvent}
            style={{ width: "100%" }}
            placeholder="Select Event"
          >
            {record?.events?.map((item, index) => (
              <Option key={index} value={item?.name} label={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate("/earnings/details/1", {
                state: {
                  type: "upcoming",
                },
              });
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/earnings/1`);
            }}
          >
            <EditFilled style={{ color: "#C69320" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm();
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: renderSwitch,
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      bankName: "HDFC bank",
      holderEmail: "john@gmail.com",
      IfscCode: "HD23qw",
      accountHolderName: "John Charles",
      status: true,
      events: [
        {
          id: "1",
          name: "Launch",
        },
      ],
    },
    {
      key: "2",
      bankName: "HDFC bank",
      holderEmail: "johnnn@gmail.com",
      IfscCode: "HD23qw",
      accountHolderName: "John Charles",
      status: false,
      events: [
        {
          id: "2",
          name: "Success",
        },
      ],
    },
    {
      key: "3",
      bankName: "HDFC bank",
      holderEmail: "john@gmail.com",
      IfscCode: "HD23qw",
      accountHolderName: "John Charles",
      status: false,
      events: [
        {
          id: "3",
          name: "Launch",
        },
      ],
    },
  ];

  return (
    <div>
      <Row
        style={{ justifyContent: "flex-end", padding: "10px 0px" }}
        gutter={16}
      >
        <Col
          style={{
            margin: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              background: "#ECAC35",
              height: 35,
              color: "black",
            }}
            type="primary"
            onClick={() => navigate("/earnings/add")}
          >
            Add Bank Details
          </Button>
        </Col>

        <Col
          style={{
            margin: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        ></Col>
      </Row>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default BankDetails;
