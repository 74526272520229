import { Card } from "antd";
import { dashboardResponse } from "../types/General";

type DashboardCardTypes = {
  data: {
    name: string;
    icon: any;
    number: number;
    prefix: string;
  };
  // dashboard: dashboardResponse;
};

const DashboardCard = ({ data }: DashboardCardTypes) => {
  return (
    <Card className="stat_card">
      <div
        style={{
          alignItems: "center",
          margin: "auto",
        }}
      >
        <div className="card_icon">
          <div>{data.icon}</div>
          <div>
            <div className="card_heading1">{data.name}</div>
            <div className="card_heading2">{`${data.prefix} ${data.number}`}</div>
          </div>
        </div>
        {/* <div>45$ this week</div> */}
      </div>
    </Card>
  );
};

export default DashboardCard;
