import { Col, Row, Select, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useState } from "react";

interface DataType {
  key: string;
  eventName: string;
  transactionAmount: string;
  eventCategory: string;
  eventDateTime: string;
  bankName: string;
}

const { Option } = Select;

const eventNames = [{ name: "Launch Party" }, { name: "Success Party" }];

const bankNames = [{ name: "HDFC" }, { name: "SBI" }];

const TransactionHistory = () => {
  const [selectedEvent, setSelectedEvent] = useState<string>();
  const [selectedBank, setSelectedBank] = useState<string>();

  const columns: ColumnsType<DataType> = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      align: "center",
    },
    {
      title: "Event Category",
      dataIndex: "eventCategory",
      key: "eventCategory",
      align: "center",
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      align: "center",
    },
    {
      title: "Transaction Amount",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
      align: "center",
    },
    {
      title: "Event Date Time",
      dataIndex: "eventDateTime",
      key: "eventDateTime",
      align: "center",
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      eventName: "Meeting",
      transactionAmount: "$200",
      eventCategory: "Music",
      eventDateTime: "20 Sep, 10:00 pm",
      bankName: "HDFC",
    },
    {
      key: "2",
      eventName: "Meeting",
      transactionAmount: "$120",
      eventCategory: "Movies",
      eventDateTime: "20 Jan,2:00 am",
      bankName: "SBI",
    },
    {
      key: "3",
      eventName: "Meeting",
      transactionAmount: "$2000",
      eventCategory: "Games",
      eventDateTime: "10 May,12:00 pm",
      bankName: "BOB",
    },
  ];

  return (
    <div>
      <Row
        style={{
          justifyContent: "space-between",
          padding: "10px 0px",
          display: "flex",
        }}
        gutter={16}
      >
        <Col
          style={{
            gap: 20,
            display: "flex",
          }}
          xs={24}
          md={24}
        >
          <Select
            allowClear
            maxLength={20}
            className="select_type withInput" 
            onChange={(val) => setSelectedEvent(val)}
            value={selectedEvent}
            style={{ width: "100%" }}
            placeholder="Select Event"
          >
            {eventNames?.map((item, index) => (
              <Option key={index} value={item?.name} label={item?.name}>
                {item?.name}
              </Option>
            ))}
          </Select>

          <Select
            allowClear
            maxLength={20}
            className="select_type withInput"
            onChange={(val) => setSelectedBank(val)}
            value={selectedBank}
            style={{ width: "100%" }}
            placeholder="Select Bank Name"
          >
            {bankNames?.map((item, index) => (
              <Option key={index} value={item?.name} label={item?.name}>
                {item?.name}
              </Option>
            ))}
          </Select>
          <Select
            style={{ width: "100%" }}
            className="select_type withInput"
            // onChange={(val) => setType(val)}
            placeholder="Filter"
            options={[
              { value: "daily", label: "Daily" },
              { value: "weekly", label: "Weekly" },
              { value: "monthly", label: "Monthly" },
              { value: "yearly", label: "Yearly" },
            ]}
          />
        </Col>
      </Row>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default TransactionHistory;
