import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  useLazyGetAllEmployeesQuery,
  useDeleteEmployeeMutation,
  usePutUpdateEmployeeMutation,
  useLazyGetUserCsvQuery,
} from "../../services/employees";
import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ContentHeader } from "../../components";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { User } from "../../types/User";

const { confirm } = Modal;

const ManageUsers = () => {
  const [getAllEventsData] = useLazyGetAllEmployeesQuery();
  const [deleteEmployee] = useDeleteEmployeeMutation();
  const [activeInactiveEmployee] = usePutUpdateEmployeeMutation();
  const [getUserCsvMethod] = useLazyGetUserCsvQuery();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allEmployees, setAllEmployees] = useState<User[]>([]);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const navigate = useNavigate();

  const getAllEmployesDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getAllEventsData({
        page,
        query,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setAllEmployees(res?.data?.employee || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const activeInactiveCategory = async (isBlocked: boolean, _id: string) => {
    const data = {
      isBlocked: isBlocked,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await activeInactiveEmployee({
        encryptedBody,
        employeeId: _id,
      }).unwrap();
      if (res?.statusCode === 200) {
        successToast("Account status change successfully");
        getAllEmployesDetails(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this employee?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteEmployee({ employeeId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Employee Deleted Successfully");
        getAllEmployesDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleExportCsv = async () => {
    try {
      setLoading(true);
      const res = await getUserCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        if (res?.data) {
          window.open(res?.data);
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message);
    }
  };

  const columns: ColumnsType<User> = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
      render: (_, { firstName, lastName }) => (
        <div>
          <span>{firstName ? firstName + " " + lastName : "-"}</span>
        </div>
      ),
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNo",
      key: "phoneNo",
      align: "center",
      render: (_, { phone, countryCode }) => (
        <div>
          <span>
            {countryCode?.includes("+") ? countryCode : `+${countryCode}`}{" "}
            {phone || ""}
          </span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-employee/user-detail/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-employee/${record?._id}`);
            }}
          >
            <EditFilled style={{ color: "#C69320" }} />
          </Button>
          <Button
            onClick={(e) => {
              showDeleteConfirm(record);
              e.preventDefault();
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => activeInactiveCategory(!isBlocked, _id)}
        />
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllEmployesDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  useEffect(() => {
    getAllEmployesDetails(1);
  }, [query]);

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn mngusrs">
      <ContentHeader
        heading="Manage Employee"
        buttonNavigateTo="manage-employee/add"
        buttonText="Add Employee"
      />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px",gap:"10px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Input
                placeholder="Search Events"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
              />
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                icon={<DownloadOutlined />}
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
                onClick={handleExportCsv}
              >
                Export CSV
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={allEmployees}
            loading={loading}
            pagination={pagination}
          />
        </Card>
      </div>
    </div>
  );
};

export default ManageUsers;
