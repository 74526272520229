import { Button, Col, Input, Modal, Row, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditFilled,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Events } from "../../types/General";
import moment from "moment";
import { useDeleteEventsMutation } from "../../services/events";
import { errorToast, successToast } from "../../helpers";

const { confirm } = Modal;

interface props {
  type: string;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalCount: number | undefined;
  getAllEventsData: (page: number) => void;
  allEvents: Events[] | [];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
}

const EventTable = ({
  type,
  currentPage,
  setCurrentPage,
  totalCount,
  getAllEventsData,
  allEvents,
  loading,
  setLoading,
  query,
  setQuery,
}: props) => {
  const navigate = useNavigate();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [deleteEventMethod] = useDeleteEventsMutation();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllEventsData(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      const res = await deleteEventMethod({ eventId: id }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        successToast("Event deleted successfully");
        getAllEventsData(currentPage);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this event?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        handleDelete(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<Events> = [
    {
      title: "Event Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Event Category",
      dataIndex: "eventCategory",
      key: "eventCategory",
      align: "center",
      render: (_, { eventCategory }) => (
        <div>
          <span>{eventCategory?.name || ""}</span>
        </div>
      ),
    },
    {
      title: "Event End Date Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      render: (_, { endTime }) => (
        <div>
          <span>{moment(endTime).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },
    {
      title: "Event Start Date Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      render: (_, { time }) => (
        <div>
          <span>{moment(time).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, { _id, isBooking }) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-events/details/${_id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {type === "upcoming" || (type === "active" && !isBooking) ? (
            <>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/manage-events/${_id}`);
                }}
              >
                <EditFilled style={{ color: "#C69320" }} />
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  showDeleteConfirm(_id);
                }}
              >
                <DeleteOutlined style={{ color: "#d4380d" }} />
              </Button>
            </>
          ) : undefined}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  return (
    <div>
      <Row
        style={{ justifyContent: "space-between", padding: "10px 0px" }}
        gutter={16}
      >
        <Col xs={24} md={7}>
          <Input
            placeholder="Search Events"
            prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
            style={{ height: 50, borderRadius: 10 }}
            value={debouncedQuery}
            onChange={(e) => setDebouncedQuery(e.target.value)}
            maxLength={20}
          />
        </Col>
        <Col
          style={{
            margin: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        ></Col>
      </Row>
      <Table
        columns={columns}
        dataSource={allEvents}
        pagination={pagination}
        rowKey="_id"
        loading={loading}
      />
    </div>
  );
};

export default EventTable;
