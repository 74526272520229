import { Button, Card, Col, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";

import { ContentHeader } from "../../../components";

const EarningForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const url = window.location.pathname;
  const path = url.includes("add");

  // const [profileImage, setProfileImage] = useState(null);

  // const onFinish = (values: any) => {
  //   console.log("Received values of form: ", values);
  //   navigate("/earnings", { replace: true });
  // };

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn">
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="earnings"
        heading={path ? "Add Bank Details" : "Edit Bank Details"}
      />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            // onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="fullname"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your name!",
                  //     whitespace: true,
                  //   },
                  //   {
                  //     min: 3,
                  //     message: "Name must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Account Holder Name</h4>
                    <Input
                      placeholder="Account Holder Name"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your E-mail!",
                  //   },
                  //   {
                  //     type: "email",
                  //     message: "The input is not valid E-mail!",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Account Holder Email</h4>
                    <Input
                      // disabled
                      style={{ height: 55 }}
                      placeholder="E-mail"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="bankName"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your name!",
                  //     whitespace: true,
                  //   },
                  //   {
                  //     min: 3,
                  //     message: "Name must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Bank Name</h4>
                    <Input placeholder="Bank Name" style={{ height: 55 }} />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="accountNumber"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your Address!",
                  //     whitespace: true,
                  //   },
                  //   {
                  //     min: 3,
                  //     message: "Address must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Account Number</h4>
                    <Input
                      placeholder="Account Number"
                      style={{ height: 55 }}
                      type="number"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="ifsc"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your name!",
                  //     whitespace: true,
                  //   },
                  //   {
                  //     min: 3,
                  //     message: "Name must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>IFSC/SWIFT code</h4>
                    <Input
                      placeholder="IFSC/SWIFT code"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    navigate("/earnings", { replace: true });
                  }}
                >
                  {path ? "Add" : "Edit"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default EarningForm;
