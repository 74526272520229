import { Card } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useLazyGetEventNotificationQuery } from "../../services/dashBoard";
import { errorToast } from "../../helpers";
import { NotificationResponse } from "../../types/General";
import moment from "moment";

const Notifications = () => {
  const [notifications, setNotifications] = useState<NotificationResponse[]>(
    []
  );
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const [getNotificationData] = useLazyGetEventNotificationQuery();
  const [rownumber, setRowNumber] = useState(0);

  const getAllNotifications = async (page: number) => {
    try {
      setLoading(true);
      const res = await getNotificationData({
        page,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setNotifications(res?.data?.notification || []);
        setTotalCount(res?.data?.notificationCount);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };
  useEffect(() => {
    getAllNotifications(1);
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllNotifications(page);
    const newRowNumber = (page - 1) * 10;
    setRowNumber(newRowNumber);
  };
  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };
  const columns: ColumnsType<NotificationResponse> = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      render: (text, record, index) => `#${rownumber + index + 1}`,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, { title }) => (
        <div>
          <span>{title || ""}</span>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, { message }) => (
        <div>
          <span>{message || ""}</span>
        </div>
      ),
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (_, { createdAt }) => (
        <div>
          <span>{moment(createdAt).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn ntfctn_mn">
      <Card style={{ overflowX: "auto" }}>
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Notification History</h2>
          </div>
          <Table
            columns={columns}
            dataSource={notifications}
            loading={loading}
            pagination={pagination}
            rowKey="_id"
          />
        </div>
      </Card>
    </div>
  );
};

export default Notifications;
