import { Button, Card, Col, Input, Row, Spin, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Events, FundBookings } from "../../../types/General";
import {
  useLazyGetFundByIdQuery,
  useLazyGetFundRaiseBookingQuery,
} from "../../../services/fundRaising";
import { useEffect, useState } from "react";
import { errorToast } from "../../../helpers";
import moment from "moment";
import Table, { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";

const FundEventDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [eventsData, setEventsData] = useState<Events>();
  const [loading, setLoading] = useState(false);
  const [allEventBookings, setAllEventBookings] = useState<FundBookings[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);

  const [getDataMethod] = useLazyGetFundByIdQuery();

  const [getBookingMethod] = useLazyGetFundRaiseBookingQuery();

  const getAllEventsData = async (page: number) => {
    try {
      if (eventsData?.eventId?._id) {
        setLoadingTable(true);
        const res = await getBookingMethod({
          eventId: eventsData?.eventId?._id,
          page,
          query,
        }).unwrap();
        setLoadingTable(false);
        if (res?.statusCode === 200) {
          setAllEventBookings(res?.data?.booking || []);
          setTotalCount(res?.data?.count);
        }
      }
    } catch (error: any) {
      setLoadingTable(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const getFundsData = async () => {
    try {
      setLoading(true);
      if (id) {
        const res = await getDataMethod({ fundId: id }).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          setEventsData(res?.data);
        }
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllEventsData(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const columns: ColumnsType<FundBookings> = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Customer Email Id",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, { user }) => (
        <div>
          <span>{user?.email || ""}</span>
        </div>
      ),
    },
    {
      title: "Customer Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (_, { user }) => (
        <div>
          <span>{`${user?.countryCode} ${user?.phone}` || ""}</span>
        </div>
      ),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      align: "center",
      render: (_, { paymentMode }) => (
        <div>
          <span>{paymentMode || ""}</span>
        </div>
      ),
    },
    {
      title: "Amount Raised",
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
    {
      title: "Booking Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      render: (_, { createdAt }) => (
        <div>
          <span>{moment(createdAt).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  useEffect(() => {
    getFundsData();
  }, []);

  useEffect(() => {
    if (eventsData) {
      getAllEventsData(1);
    }
  }, [query, eventsData]);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }} className="evtsdtl_mn mngevent">
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Fundraising Event Detail</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/manage-fund-event", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <h4>Images/Videos of the event</h4>
            <div>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <div
                    style={{
                      display: "flex",
                      gap: 20,
                    }}
                  >
                    {eventsData?.images?.length
                      ? eventsData?.images?.map((item, index) => {
                          return (
                            <figure key={index}>
                              {/* @ts-ignore */}
                              {item?.split(".").pop() === "mp4" ? (
                                <video
                                  muted
                                  src={item}
                                  autoPlay
                                  style={{
                                    height: 150,
                                    borderRadius: 10,
                                    width: 150,
                                    // margin: "auto",
                                  }}
                                />
                              ) : (
                                <img
                                  alt=""
                                  src={item}
                                  style={{
                                    height: 150,
                                    borderRadius: 10,
                                    width: 150,
                                    // margin: "auto",
                                  }}
                                />
                              )}
                            </figure>
                            // <figure key={index}>
                            //   <img
                            //     alt=""
                            //     src={item}
                            //     style={{
                            //       height: 150,
                            //       borderRadius: 10,
                            //       width: 150,
                            //       // margin: "auto",
                            //     }}
                            //   />
                            // </figure>
                          );
                        })
                      : undefined}
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Event Name</h4>
                <h5>{eventsData?.name || "-"}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Event Description</h4>
                <h5>{eventsData?.description || "-"}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Event Date Time</h4>
                <h5>
                  {moment(eventsData?.time).format("MMM Do YYYY, h:mm a")}
                </h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Money Goal</h4>
                <h5>${eventsData?.moneyGoal || "0"}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Total Collected Fund</h4>
                <h5>${eventsData?.totalAmountCollected || "0"}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Donation Goals</h4>
                <h5
                  dangerouslySetInnerHTML={{
                    __html: eventsData?.detail || "-",
                  }}
                ></h5>
              </Col>
            </Row>
          </div>
        </Card>
      </Spin>
      <Card>
        <div>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
              />
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            ></Col>
          </Row>
          <Table
            columns={columns}
            dataSource={allEventBookings}
            pagination={pagination}
            rowKey="_id"
            loading={loadingTable}
          />
        </div>
      </Card>
    </div>
  );
};

export default FundEventDetails;
