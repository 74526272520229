import { Button, Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

const EarningDetails = () => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }} className="evtsdtl_mn">
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Bank details</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/earnings", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Account Holder Name</h4>
              <h5>John Brown</h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Account Holder Email</h4>
              <h5>johnbrown10@gmail.com</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Bank Name</h4>
              <h5>HDFC Bank</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Account Number</h4>
              <h5>4242 4242 4242 4242</h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>IFSC/SWIFT code</h4>
              <h5>hd332Rt</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default EarningDetails;
