import { InboxOutlined } from "@ant-design/icons";
import { Upload, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import { Dispatch, SetStateAction } from "react";
const { Dragger } = Upload;
const ProductImages = ({
  imageObject,
  imagePreview,
  setImageObject,
  setImagePreview,
  accept,
}: {
  imageObject: RcFile[] | number[];
  setImageObject: Dispatch<SetStateAction<RcFile[] | number[]>>;
  imagePreview: string[];
  setImagePreview: Dispatch<SetStateAction<string[]>>;
  accept: any;
}) => {
  const props: UploadProps = {
    name: "file",
    multiple: true,
    maxCount: 6,
    accept: accept || "image/png, image/gif, image/jpeg, image/jpg, video/*",
    beforeUpload(file: RcFile, fileList: RcFile[]) {
      let files = [...fileList];
      if (fileList?.length > 6) {
        files = files?.slice(0, 6);
      }
      setImageObject([...imageObject, ...files] as any);
      let newArr = files.map((item) => window.URL.createObjectURL(item));
      setImagePreview([...imagePreview, ...newArr]);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div>
      <Dragger {...props}>
        <div style={{ padding: "0px 20px" }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Select or drag images to this area.</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload.
          </p>
        </div>
      </Dragger>
    </div>
  );
};
export default ProductImages;
