import { END_POINTS } from "../helpers";
import { Cms, NotificationResponse, dashboardResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  message: string;
};

type notificationResponseType = {
  notificationCount: number;
  pages: number;
  notification: NotificationResponse[];
};
export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      CommonResponse & { data: dashboardResponse },
      { bookingFilter: string; revenueFilter: string }
    >({
      query: ({ bookingFilter, revenueFilter }) => ({
        url: `${END_POINTS.dashboard}?bookingFilter=${bookingFilter}&revenueFilter=${revenueFilter}`,
        method: "GET",
      }),
    }),

    getCms: builder.query<CommonResponse & { data: Cms }, {}>({
      query: () => ({
        url: `${END_POINTS.cms}?panel=event`,
        method: "GET",
      }),
    }),

    getEventNotification: builder.query<
      CommonResponse & { data: notificationResponseType },
      { page: number }
    >({
      query: ({ page }) => ({
        url: `${END_POINTS.eventNotification}?page=${page}&size=10`,
        method: "GET",
      }),
    }),

    logoutApi: builder.query<CommonResponse, {}>({
      query: () => ({
        url: `${END_POINTS.logout}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetDashboardQuery,
  useLazyGetEventNotificationQuery,
  useLazyGetCmsQuery,
  useLazyLogoutApiQuery,
} = dashboardApi;
