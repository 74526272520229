import { END_POINTS } from "../helpers";
import {
  CommonBody,
  EventCategory,
  Events,
  FundBookings,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: {
    count: number;
    fundRaising: Events[] | [];
  };
  message: string;
};

type EventCategoryResponse = {
  statusCode: number;
  data?: { eventCategory: EventCategory[] };
  message: string;
};

type EditParams = {
  body: CommonBody;
  fundId: string;
};

type BookingResponse = {
  booking: FundBookings[];
  count: number;
};

export const fundRaisingApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addFunds: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.fundRaising,
        method: "POST",
        body,
      }),
    }),

    editFunds: builder.mutation<CommonResponseType, EditParams>({
      query: ({ body, fundId }) => ({
        url: `${END_POINTS.fundRaising}/${fundId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteFunds: builder.mutation<CommonResponseType, { fundId: string }>({
      query: ({ fundId }) => ({
        url: `${END_POINTS.fundRaising}/${fundId}`,
        method: "DELETE",
      }),
    }),

    getAllFunds: builder.query<
      CommonResponseType,
      { page: number; query: string }
    >({
      query: ({ page, query }) => ({
        url: `${END_POINTS.fundRaising}?page=${page}&size=10&search=${query}&all=false`,
        method: "GET",
      }),
    }),

    getFundById: builder.query<
      CommonResponseType & { data: Events },
      { fundId: string }
    >({
      query: ({ fundId }) => ({
        url: `${END_POINTS.fundRaising}/${fundId}`,
        method: "GET",
      }),
    }),

    getEventsCategory: builder.query<EventCategoryResponse, {}>({
      query: () => ({
        url: END_POINTS.eventCategory + "?size=50&page=1",
        method: "GET",
      }),
    }),

    getFundRaiseBooking: builder.query<
      CommonResponseType & {
        data: BookingResponse;
      },
      { eventId: string; page: number; query: string }
    >({
      query: ({ eventId, page, query }) => ({
        url:
          END_POINTS.fundRaiseBooking +
          `?eventId=${eventId}&page=${page}&size=10&search=${query}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddFundsMutation,
  useEditFundsMutation,
  useDeleteFundsMutation,
  useLazyGetAllFundsQuery,
  useLazyGetFundByIdQuery,

  useLazyGetFundRaiseBookingQuery,
} = fundRaisingApi;
