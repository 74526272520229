import { Col, Layout, Row, Spin } from "antd";

import "./dashboard.css";
import {
  DollarCircleOutlined,
  BoldOutlined,
  LineChartOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import { DashboardCard } from "../../components";
import EventDetailTable from "../../components/tables/EventDetailTable";
import { useLazyGetDashboardQuery } from "../../services/dashBoard";
import { useEffect, useState } from "react";
import { Events, dashboardResponse } from "../../types/General";
import { errorToast } from "../../helpers";
import { useLazyGetAllEventsQuery } from "../../services/events";
import EarningGraph from "../../components/charts/EarningGraph";
import BookingsGraph from "../../components/charts/BookingEarningGraph";

const Dashboard = () => {
  const { Content } = Layout;
  const [dashboardData, setDashboardData] = useState<dashboardResponse>();
  const [dashboard, getDashboard] = useLazyGetDashboardQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [bookingsFilter, setBookingsFilter] = useState<string>("monthly");
  const [earningsFilter, setEarningsFilter] = useState<string>("monthly");
  const [bookingsNames, setBookingsNames] = useState<string[]>([]);
  const [bookingsValues, setBookingsValues] = useState<number[]>([]);
  const [earningNames, setEarningNames] = useState<string[]>([]);
  const [earningValues, setEarningValues] = useState<number[]>([]);
  const [allEvents, setAllEvents] = useState<Events[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState("");

  const [getEventsMethod] = useLazyGetAllEventsQuery();

  const getAllEventsData = async (page: number) => {
    try {
      setLoading(true);
      const res = await getEventsMethod({
        page,
        query,
        activeTab: 1,
        size: 10,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setAllEvents(res?.data?.event || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const getDashboardData = async () => {
    try {
      setLoading(true);
      const res = await dashboard({
        bookingFilter: bookingsFilter,
        revenueFilter: earningsFilter,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setDashboardData(res?.data);
        setBookingsNames(Object.keys(res?.data?.bookingReceived));
        setBookingsValues(Object.values(res?.data?.bookingReceived));
        setEarningNames(Object.keys(res?.data?.totalEarning));
        setEarningValues(Object.values(res?.data?.totalEarning));
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getDashboardData();
    getAllEventsData(1);
  }, [bookingsFilter, earningsFilter]);

  const STATS = [
    {
      name: "Total Bookings Received",
      icon: <BoldOutlined style={{ color: "green" }} />,
      number: dashboardData?.bookingCount || 0,
      prefix: "#",
    },
    {
      name: "Total Events",
      icon: <AlignLeftOutlined style={{ color: "rgb(255, 120, 45)" }} />,
      number: dashboardData?.eventCount || 0,
      prefix: "#",
    },
    {
      name: "Total Fundraising Events",
      icon: <LineChartOutlined style={{ color: "rgb(255, 170, 44)" }} />,
      number: dashboardData?.fundRaisingCount || 0,
      prefix: "#",
    },
    {
      name: "Total Earnings",
      icon: <DollarCircleOutlined style={{ color: "rgb(0, 173, 163)" }} />,
      number: dashboardData?.totalRevenue || 0,
      prefix: "$",
    },
  ];

  const graphData = {
    labels: bookingsNames,
    datasets: [
      {
        label: "Bookings",
        data: bookingsValues,
        borderColor: "#ECAC35",
        backgroundColor: "#C69320",
      },
    ],
  };

  const graphCustomerData = {
    labels: earningNames,
    datasets: [
      {
        label: "Earnings",
        data: earningValues,
        borderColor: "#ECAC35",
        backgroundColor: "#C69320",
      },
    ],
  };

  return (
    <Content
      style={{
        margin: "0px 10px",
        padding: 35,
        minHeight: 280,
      }}
    >
      <div className="hdng">
        <h1>Dashboard</h1>
      </div>
      <Spin spinning={loading} className="dshbrd">
        <div className="top_bar">
          {STATS.map((item, index) => (
            <DashboardCard data={item} key={index} />
          ))}
        </div>

        <div
          style={{
            margin: "20px 0px",
          }}
        >
          <Row gutter={16}>
            <Col lg={12} md={24} sm={24} xs={24} className="erngtbl">
              <BookingsGraph
                bookingsFilter={bookingsFilter}
                setBookingsFilter={setBookingsFilter}
                graphData={graphData}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} className="tblgrph">
              <EarningGraph
                earningsFilter={earningsFilter}
                setEarningsFilter={setEarningsFilter}
                graphCustomerData={graphCustomerData}
              />
            </Col>
          </Row>
        </div>
        <div style={{ fontSize: 17 }} className="hdng">
          <h1>Event Details</h1>
        </div>
                                                                                                                                                      
        <EventDetailTable
          setQuery={setQuery}
          type="active"
          totalCount={totalCount}
          getAllEventsData={getAllEventsData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allEvents={allEvents}
          loading={loading}
          setLoading={setLoading}
          query={query}
        />
      </Spin>
    </Content>
  );
};

export default Dashboard;
