import "../auth/login/login.css";
import { Button, Card, Form, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AuthWrapper from "../../layout/authWrapper";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { usePostChangePasswordMutation } from "../../services/auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const [changePasswordMethod, changePasswordMutation] =
    usePostChangePasswordMutation();

  const onFinish = async (values: any) => {
    const data = {
      oldPassword: values.oldPassword,
      password: values.password,
    };

    console.log(data, ">>>>");

    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      const res = await changePasswordMethod(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        successToast("Password changed successfully");
        navigate("/dashboard", { replace: true });
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleConfirmPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords do not match.");
    } else {
      callback();
    }
  };

  const validateConfirmPassword = ({
    getFieldValue,
  }: {
    getFieldValue: any;
  }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Passwords do not match."));
    },
  });

  const handleNewPassword = ({ getFieldValue }: { getFieldValue: any }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("oldPassword") !== value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("Old and new password need not to be same.")
      );
    },
  });

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="bck_arrw_div">
          <ArrowLeftOutlined
            style={{ fontSize: 20, cursor: "pointer" }}
            className="bck_arrw"
            onClick={() => navigate("/dashboard", { replace: true })}
          />
          <h3>Change Password</h3>
        </div>
        <Spin spinning={changePasswordMutation?.isLoading}>
          <div className="login_img">
            {/* <img src="/static/images/logo.png" alt="" /> */}
          </div>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{
              maxWidth: 400,
              marginTop: 20,
              minWidth: 400,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            form={form}
            autoComplete="off"
          >
            <Form.Item
              name="oldPassword"
              className="pswrd"
              rules={[
                { required: true, message: "Please input your old password!" },
                {
                  min: 6,
                  message: "Old Password must include at least 6 characters",
                },
                {
                  max: 15,
                  message: "Old Password should not be more than 15 characters",
                },
              ]}
            >
              <Input.Password
                style={{ width: "400px", height: "50px" }}
                value={password}
                onChange={(e) => {
                  if (e.target.value === " " || e.target.value === ".") {
                  } else {
                    setPassword(e.target.value);
                  }
                }}
                placeholder="Old Password"
              />
            </Form.Item>
            <Form.Item
              name="password"
              className="pswrd"
              dependencies={["oldPassword"]}
              rules={[
                { required: true, message: "Please input your password!" },
                {
                  min: 6,
                  message: "Password must include at least 6 characters",
                },
                {
                  max: 15,
                  message: "Password should not be more than 15 characters",
                },
                handleNewPassword,
                // {
                //   validator: handleNewPassword,
                // },
              ]}
            >
              <Input.Password
                style={{ width: "400px", height: "50px" }}
                value={password}
                onChange={(e) => {
                  if (e.target.value === " " || e.target.value === ".") {
                  } else {
                    setPassword(e.target.value);
                  }
                }}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                validateConfirmPassword,
                // {
                //    validator: validateConfirmPassword, dependencies: ['password']
                // },
              ]}
            >
              <Input.Password
                style={{ width: "400px", height: "50px" }}
                placeholder="Confirm Password"
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                    fontWeight: 600,
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={changePasswordMutation?.isLoading}
                >
                  Change Password
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </AuthWrapper>
  );
};

export default ChangePassword;
