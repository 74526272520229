import { Button, Card, Col, Form, Input, Row, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UploadOutlined } from "@ant-design/icons";
import { ContentHeader } from "../../components";
import useAuth from "../../hooks/useAuth";
import { RcFile } from "antd/es/upload";
import {
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../helpers";
import { UploadMedia } from "../../utils/mediaUpload";
import { usePutUpdateProfileMutation } from "../../services/auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { getToken, setCredentials } from "../../reducer/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { isString } from "../../utils/validations";

const Profile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const userData = useAuth();
  const token = useAppSelector(getToken);

  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryName, setCountryName] = useState("us");
  const [phoneCode, setPhoneCode] = useState("+1");
  const [phoneNo, setPhoneNo] = useState("");
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    about: "",
    email: "",
  });
  const [error, setError] = useState(false);

  const [updateProfileMethod] = usePutUpdateProfileMutation();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);

    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 6) {
      setError(true);
      return;
    }

    setError(false);

    let data: {
      firstName: any;
      lastName: any;
      email: any;
      countryCode: string;
      phone: string;
      about: any;
      image?: any; // Add the 'image' property as optional
      countryName: string;
    } = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      countryCode: phoneCode?.includes("+") ? phoneCode : `+${phoneCode}`,
      countryName: countryName,
      phone: phoneNo,
      // image: profileImage || "",
      about: values.about,
    };
    if (profileImage) {
      data.image = profileImage;
    }
    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      setLoading(true);
      const res = await updateProfileMethod(encryptedBody).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        navigate("/dashboard", { replace: true });
        successToast("Profile updated successfully");
        setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
        dispatch(
          setCredentials({
            user: res?.data || null,
            token: token || null,
          })
        );
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const handleImageUpload = async (file: RcFile, key: string) => {
    if (file?.type.includes("image")) {
      try {
        setLoading(true);
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setProfileImage(res?.data);
        }
        setLoading(false);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
      }
    } else {
      errorToast("Only images are allowed");
    }
  };

  useEffect(() => {
    if (userData) {
      setPhoneCode(userData?.countryCode || "+1");
      setPhoneNo(userData?.phone || "");
      setProfileImage(userData?.image || "");
      setValues({
        ...values,
        email: userData?.email || "",
        firstName: userData?.firstName || "",
        lastName: userData?.lastName || "",
        about: userData?.about || "",
      });
      form.setFieldsValue({
        email: userData?.email,
        firstName: userData?.firstName,
        phone: userData?.phone,
        lastName: userData?.lastName,
        about: userData?.about,
      });
    }
  }, [userData]);

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn">
      <ContentHeader heading="Update Profile" />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="profileImage">
                  <Upload
                    beforeUpload={(file) => handleImageUpload(file, "profile")}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {profileImage ? (
                      <img
                        className="img_profile"
                        src={profileImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      max: 20,
                      message: "Name must be at most 20 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>First Name</h4>
                    <Input
                      value={values.firstName}
                      onChange={(e) => {
                        if (isString(e.target.value)) {
                          setValues({ ...values, firstName: e.target.value });
                        }
                      }}
                      placeholder="First Name"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      max: 20,
                      message: "Name must be at most 20 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Last Name</h4>
                    <Input
                      value={values.lastName}
                      onChange={(e) => {
                        if (isString(e.target.value)) {
                          setValues({ ...values, lastName: e.target.value });
                        }
                      }}
                      placeholder="Last Name"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  // label="Username"
                  name="about"
                  rules={[
                    {
                      required: true,
                      message: "Please input your description!",
                    },
                    {
                      min: 10,
                      message: "Description must be at least 10 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>About</h4>
                    <Input
                      value={values.about}
                      onChange={(e) =>
                        setValues({ ...values, about: e.target.value })
                      }
                      placeholder="About"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      disabled={userData?.isEmailVerify}
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      value={values.email}
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        country={"us"}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                        // className={
                        //   error && !phoneNo ? "select_type2" : "select_type"
                        // }
                        disabled={userData?.isPhoneVerify}
                        // disableCountryCode={userData?.isPhoneVerify}
                      />
                    </div>
                  </div>
                  {error && !phoneNo ? (
                    <h3 className="error_msg">
                      Please input your phone number!
                    </h3>
                  ) : null}
                  {error && phoneNo?.length && phoneNo?.length < 6 ? (
                    <h3 className="error_msg">
                      Please input valid phone number!
                    </h3>
                  ) : null}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  className="btn-style"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => setError(true)}
                >
                  Update
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Profile;
