import { Button, Card, Col, Input, Row, Space } from "antd";

import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Events } from "../../types/General";
import moment from "moment";

interface DataType {
  key: string;
  eventName: string;
  hostName: string;
  eventCategory: string;
  eventDateTime: string;
  status: boolean;
}
type TableDatatype = {
  type: string;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalCount: number | undefined;
  getAllEventsData: (page: number) => void;
  allEvents: Events[] | [];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
};

const EventDetailTable = ({
  type,
  currentPage,
  setCurrentPage,
  totalCount,
  getAllEventsData,
  allEvents,
  loading,
  setLoading,
  query,
  setQuery,
}: TableDatatype) => {
  const navigate = useNavigate();
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllEventsData(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const columns: ColumnsType<Events> = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      align: "center",
      render: (_, { name }) => (
        <div>
          <span>{name || ""}</span>
        </div>
      ),
    },
    {
      title: "Event Category",
      dataIndex: "eventCategory",
      key: "eventCategory",
      align: "center",
      render: (_, { eventCategory }) => (
        <div>
          <span>{eventCategory?.name || ""}</span>
        </div>
      ),
    },
    {
      title: "Event End Date Time",
      dataIndex: "eventDateTime",
      key: "eventDateTime",
      align: "center",
      render: (_, { endTime }) => (
        <div>
          <span>{moment(endTime).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },
    {
      title: "Event Start Date Time",
      dataIndex: "eventDateTime",
      key: "eventDateTime",
      align: "center",
      render: (_, { time }) => (
        <div>
          <span>{moment(time).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, { _id }) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-events/details/${_id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  return (
    <div style={{}}>
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}>
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
              />
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={allEvents}
            pagination={pagination}
            rowKey="_id"
            loading={loading}
          />
        </Card>
      </div>
    </div>
  );
};

export default EventDetailTable;
