import { useState } from "react";
import { Card, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";
import TransactionHistory from "./TransactionHistory";
import BankDetails from "./BankDetails";

const ManageEarnings = () => {
  const [activeTab, setActiveTab] = useState("1");

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Manage Bank Details`,
      children: <BankDetails />,
    },
    {
      key: "2",
      label: `Transaction History`,
      children: <TransactionHistory />,
    },
  ];
  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn mngerngs_mn">
      <ContentHeader heading="Manage Earnings" />
      <Card>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </Card>
    </div>
  );
};

export default ManageEarnings;
