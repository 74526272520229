import { Route, Routes } from "react-router-dom";
import {
  Dashboard,
  ManageUsers,
  Settings,
  Profile,
  UsersForm,
  UserDetails,
  ChangePassword,
  ManageEvents,
  EventDetails,
  EventsForm,
  ManageEventBooking,
  EventBookingDetails,
  EventBookingForm,
  FundEvents,
  FundEventDetails,
  FundEventForm,
  Notifications,
  EditDocuments,
  ManageEarnings,
  EarningDetails,
  EarningForm,
} from "../pages";
import {
  ForgetPassword,
  Login,
  ProfileSetUp,
  ResetPassword,
  SignUp,
  VerifyOtp,
} from "../pages/auth";
import MainLayout from "../layout";

const RoutesNavigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/sign-up" element={<SignUp />}></Route>
      <Route path="/verify-otp" element={<VerifyOtp />}></Route>
      <Route path="/forgot-password" element={<ForgetPassword />}></Route>
      <Route path="/profile" element={<ProfileSetUp />}></Route>
      <Route path="/reset-password" element={<ResetPassword />}></Route>
      <Route path="/change-password" element={<ChangePassword />}></Route>
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/edit-profile"
        element={
          <MainLayout>
            <Profile />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/edit-documents"
        element={
          <MainLayout>
            <EditDocuments />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-employee"
        element={
          <MainLayout>
            <ManageUsers />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-employee/:view"
        element={
          <MainLayout>
            <UsersForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-employee/user-detail/:id"
        element={
          <MainLayout>
            <UserDetails />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/notifications"
        element={
          <MainLayout>
            <Notifications />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-events"
        element={
          <MainLayout>
            <ManageEvents />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-events/details/:id"
        element={
          <MainLayout>
            <EventDetails />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-events/:view"
        element={
          <MainLayout>
            <EventsForm />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-event-booking"
        element={
          <MainLayout>
            <ManageEventBooking />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-event-booking/details/:id"
        element={
          <MainLayout>
            <EventBookingDetails />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-event-booking/:view"
        element={
          <MainLayout>
            <EventBookingForm />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-fund-event"
        element={
          <MainLayout>
            <FundEvents />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-fund-event/details/:id"
        element={
          <MainLayout>
            <FundEventDetails />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-fund-event/:view"
        element={
          <MainLayout>
            <FundEventForm />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/earnings"
        element={
          <MainLayout>
            <ManageEarnings />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/earnings/details/:id"
        element={
          <MainLayout>
            <EarningDetails />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/earnings/:view"
        element={
          <MainLayout>
            <EarningForm />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/support"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      ></Route>
    </Routes>
  );
};

export default RoutesNavigation;
