// development
export const API_URL: string =
  "https://dicksonapi.appgrowthcompany.com/api/v1/admin/";

export const END_POINTS = {
  login: "login",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "setPassword",
  getProfile: "profile",
  updateProfile: "updateProfile",
  changePassword: "changePassword",
  mediaUpload: "upload",
  signUp: "eventSignup",

  // manage events
  events: "event",

  // event category
  eventCategory: "eventCategory",

  //manage employee
  employee: "employee",
  exportCsv: "eventEmployeeExports",

  // fundraising
  fundRaising: "fundRaising",
  fundRaiseBooking: "fundRaisingBooking",

  //dashboard
  dashboard: "eventDashboard",

  //notification
  eventNotification: "eventNotification",

  // cms
  cms: "cms",

  // event booking
  eventBooking: "eventBooking",
  cancelBooking: "cancelEventBooking",

  // logout
  logout: "logout",
};
