import { useEffect, useState } from "react";
import { Card, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";

import EventTable from "./EventTable";
import { useLazyGetAllEventsQuery } from "../../services/events";
import { errorToast } from "../../helpers";
import { Events } from "../../types/General";

const ManageEvents = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [allEvents, setAllEvents] = useState<Events[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  const [getEventsMethod] = useLazyGetAllEventsQuery();

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  const getAllEventsData = async (page: number) => {
    try {
      setLoading(true);
      const res = await getEventsMethod({
        page,
        query,
        activeTab: Number(activeTab),
        size: 10,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setAllEvents(res?.data?.event || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Active Events`,
      children: (
        <EventTable
          type="active"
          totalCount={totalCount}
          getAllEventsData={getAllEventsData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allEvents={allEvents}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
        />
      ),
    },
    {
      key: "2",
      label: `Upcoming Events`,
      children: (
        <EventTable
          type="upcoming"
          totalCount={totalCount}
          getAllEventsData={getAllEventsData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allEvents={allEvents}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
        />
      ),
    },
    {
      key: "3",
      label: "Old Events",
      children: (
        <EventTable
          type="old"
          totalCount={totalCount}
          getAllEventsData={getAllEventsData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allEvents={allEvents}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
        />
      ),
    },
  ];

  useEffect(() => {
    getAllEventsData(1);
  }, [activeTab, query]);

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn mngevent">
      <ContentHeader
        heading="Manage Events"
        buttonNavigateTo="manage-events/add"
        buttonText="Add Event"
      />
      <Card style={{ overflowX: "scroll" }}>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          // pagination={pagination}
        />
      </Card>
    </div>
  );
};

export default ManageEvents;
