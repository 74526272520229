// @ts-nocheck
import { Card, Col, Form, Input, Row, Select, DatePicker } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import { EventCategory } from "../../../types/General";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { Autocomplete } from "@react-google-maps/api";

const { Option } = Select;

type values = {
  eventName: string;
  eventDescription: string;
  // hostName: string;
  location: string;
};

type ManageProps = {
  CatOptions: {
    name: string;
  }[];
  selectedCategory: string | undefined;
  setSelectedCategory: Dispatch<SetStateAction<string | undefined>>;
  setEventValues: Dispatch<SetStateAction<values>>;
  eventValues: values;
  eventCategory: EventCategory[] | [];
  setStartDateTime: Dispatch<SetStateAction<dayjs.Dayjs | null>>;
  startDateTime: dayjs.Dayjs | null;
  endDateTime: dayjs.Dayjs | null;
  setEndDateTime: Dispatch<SetStateAction<dayjs.Dayjs | null>>;
  error: boolean;
  setLong: Dispatch<SetStateAction<string>>;
  setLat: Dispatch<SetStateAction<string>>;
  form: any;
};

const EventDetailsForm = ({
  CatOptions,
  selectedCategory,
  setSelectedCategory,
  setEventValues,
  eventValues,
  eventCategory,
  setStartDateTime,
  startDateTime,
  endDateTime,
  setEndDateTime,
  error,
  setLat,
  setLong,
  form,
}: ManageProps) => {
  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const [autocomplete, setAutocomplete] = useState(null);

  const handleDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setStartDateTime(date);
    setEndDateTime(null);
  };

  const disabledEndDate = (current) => {
    if (!startDateTime || !current) {
      return false;
    }
    return current < startDateTime.startOf("day");
  };

  const handleEndDateChange = (
    date: dayjs.Dayjs | null,
    dateString: string
  ) => {
    if (startDateTime && date < startDateTime) {
      return true;
    }
    setEndDateTime(date);
    return false;
  };

  const disabledDate = (current: Dayjs | null) => {
    return current && current.isBefore(dayjs(), "day");
  };

  const onKeypress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const onLoad = (autocompleteObj) => {
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = async (e, val) => {
    if (autocomplete) {
      var place = await autocomplete.getPlace();

      if (place) {
        form.setFieldsValue({
          location: place.formatted_address,
        });

        setEventValues({
          ...eventValues,
          location: place.formatted_address,
        });

        // Additionally, you can set lat and long here if needed
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLat(lat);
        setLong(lng);
      }
    }
  };

  return (
    <Card>
      <h3>Event Details</h3>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="eventName"
            rules={[
              {
                required: true,
                message: "Please input event name!",
                whitespace: true,
              },
              {
                min: 3,
                message: "Name must be at least 3 characters",
              },
            ]}
          >
            <div>
              <h4>Event Name</h4>
              <Input
                value={eventValues.eventName}
                onChange={(e) =>
                  setEventValues({ ...eventValues, eventName: e.target.value })
                }
                placeholder="Event Name"
                style={{ height: 55 }}
              />
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="eventCategory"
            // rules={[
            //   { required: true, message: "Please input event category!" },
            // ]}
          >
            <div>
              <h4>Event Category</h4>
              <Select
                allowClear
                maxLength={20}
                className={
                  error && !selectedCategory ? "select_type2" : "select_type"
                }
                onChange={(val) => setSelectedCategory(val)}
                value={selectedCategory}
                // style={{ width: "100%" }}
                placeholder="Event Category"
              >
                {eventCategory?.map((item, index) => (
                  <Option key={index} value={item?._id} label={item?.name}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </div>
            {error && !selectedCategory ? (
              <h3 className="error_msg">This field is required </h3>
            ) : null}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="eventDescription"
            rules={[
              {
                required: true,
                message: "Please input event description!",
                whitespace: true,
              },
              {
                min: 10,
                message: "Description must be at least 10 characters",
              },
            ]}
          >
            <div>
              <h4>Event Description</h4>
              <Input
                value={eventValues.eventDescription}
                onChange={(e) =>
                  setEventValues({
                    ...eventValues,
                    eventDescription: e.target.value,
                  })
                }
                placeholder="Event Description"
                style={{ height: 55 }}
              />
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name="dateTime">
            <div>
              <h4>Event Start Date Time</h4>
              <DatePicker
                style={{ height: 55, width: "100%" }}
                showTime={{
                  format: "HH:mm",
                }}
                onChange={handleDateChange}
                // onOk={onOk}
                allowClear={false}
                value={startDateTime}
                disabledDate={disabledDate}
                className={error && !startDateTime ? "select_type2" : ""}
              />
            </div>
            {error && !startDateTime ? (
              <h3 className="error_msg">This field is required </h3>
            ) : null}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name="dateTime">
            <div>
              <h4>Event End Date Time</h4>
              <DatePicker
                style={{ height: 55, width: "100%" }}
                showTime={{
                  format: "HH:mm",
                }}
                onChange={handleEndDateChange}
                // onOk={onOk}
                allowClear={false}
                value={endDateTime}
                disabledDate={disabledEndDate}
                className={error && !endDateTime ? "select_type2" : ""}
              />
            </div>
            {error && !endDateTime ? (
              <h3 className="error_msg">This field is required </h3>
            ) : null}
          </Form.Item>
        </Col>
        {/* <Col xs={24} md={12}>
          <Form.Item
            name="hostName"
            rules={[
              { required: true, message: "Please input Host Name!" },
              {
                min: 3,
                message: "Host Name must be at least 3 characters",
              },
            ]}
          >
            <div>
              <h4>Host Name</h4>
              <Input
                value={eventValues.hostName}
                onChange={(e) =>
                  setEventValues({ ...eventValues, hostName: e.target.value })
                }
                placeholder="Host Name"
                style={{ height: 55 }}
              />
            </div>
          </Form.Item>
        </Col> */}
        <Col xs={24} md={12}>
          <Form.Item
            name="location"
            rules={[
              {
                required: true,
                message: "Please input your Location!",
                whitespace: true,
              },
              {
                min: 3,
                message: "Location must be at least 3 characters",
              },
            ]}
          >
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <div>
                <h4>Location</h4>
                <Input
                  value={eventValues.location}
                  onChange={(e) =>
                    setEventValues({ ...eventValues, location: e.target.value })
                  }
                  placeholder="Location"
                  style={{ height: 55 }}
                  onKeyDown={onKeypress}
                  // allowClear
                />
              </div>
            </Autocomplete>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default EventDetailsForm;
