import { Card, Col, Row, Spin } from "antd";
import { ContentHeader } from "../../components";
import { useEffect, useState } from "react";
import { errorToast } from "../../helpers";
import { Cms } from "../../types/General";
import { useLazyGetCmsQuery } from "../../services/dashBoard";

const Settings = () => {
  const [cmsData, setCmsData] = useState<Cms>();

  const [getCmsMethod, getCmsMutation] = useLazyGetCmsQuery();

  const getCmsData = async () => {
    try {
      const res = await getCmsMethod({}).unwrap();
      if (res?.statusCode === 200) {
        setCmsData(res?.data);
      }
    } catch (error: any) {
      console.log(error);

      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getCmsData();
  }, []);

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn">
      <ContentHeader heading="Support" />
      <Spin spinning={getCmsMutation?.isLoading}>
        <Card className="cdddddd">
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h4>Support Phone Number</h4>

              <a
                href={`tel:${cmsData?.eventCountryCode} ${cmsData?.eventPhone}`}
                className="contactList_item"
              >
                <h5>{`${cmsData?.eventCountryCode} ${cmsData?.eventPhone}`}</h5>
              </a>
            </Col>

            <Col xs={24} md={12}>
              <h4>Support Email Address</h4>
              <a
                href={`mailto:${cmsData?.eventEmail}`}
                target="_blank"
                className="contactList_item"
              >
                <h5>{cmsData?.eventEmail}</h5>
              </a>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
};

export default Settings;
