import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PutUpdateParams = {
  employeeId: string | undefined;
  encryptedBody: CommonBody;
};

export const eventApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEmployees: builder.query<
      CommonResponse,
      { page: number; query: string }
    >({
      query: ({ page, query }) => ({
        url: `${END_POINTS.employee}?page=${page}&size=10&search=${query}`,
        method: "GET",
      }),
    }),

    getEmployeeById: builder.query<
      CommonResponse,
      { employeeId: string | undefined }
    >({
      query: ({ employeeId }) => ({
        url: `${END_POINTS.employee}/${employeeId}`,
        method: "GET",
      }),
    }),

    deleteEmployee: builder.mutation<
      CommonResponse,
      { employeeId: string | undefined }
    >({
      query: ({ employeeId }) => ({
        url: `${END_POINTS.employee}/${employeeId}`,
        method: "DELETE",
      }),
    }),

    addEmployee: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.employee,
        method: "POST",
        body,
      }),
    }),

    putUpdateEmployee: builder.mutation<CommonResponse, PutUpdateParams>({
      query: ({ encryptedBody, employeeId }) => ({
        url: `${END_POINTS.employee}/${employeeId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    getUserCsv: builder.query<
      {
        statusCode: number;
        data: string;
      },
      unknown
    >({
      query: () => ({
        url: END_POINTS.exportCsv,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllEmployeesQuery,
  useLazyGetEmployeeByIdQuery,
  useDeleteEmployeeMutation,
  useAddEmployeeMutation,
  usePutUpdateEmployeeMutation,
  useLazyGetUserCsvQuery,
} = eventApi;
