import { Dispatch, SetStateAction, useEffect } from "react";
import { Dropdown, Layout, MenuProps, Modal } from "antd";
import {
  BellOutlined,
  CaretDownOutlined,
  UserOutlined,
  LogoutOutlined,
  KeyOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../reducer/authSlice";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
} from "../helpers";
import { useAppDispatch } from "../hooks/store";
import { useLazyGetProfileQuery } from "../services/auth";
import useAuth from "../hooks/useAuth";
import { useLazyLogoutApiQuery } from "../services/dashBoard";

const { confirm } = Modal;

const HeaderBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAuth();

  const [getProfileMethod] = useLazyGetProfileQuery();
  const [handleLogoutMethod] = useLazyLogoutApiQuery();

  const handleLogout = async () => {
    console.log("OK");
    try {
      const res = await handleLogoutMethod({}).unwrap();
      if (res?.statusCode === 200) {
        setTimeout(() => {
          dispatch(
            setCredentials({
              user: null,
              token: null,
            })
          );
          removeFromStorage(STORAGE_KEYS.token);

          navigate("/", { replace: true });
        }, 500);
      }
    } catch (error: any) {
      setTimeout(() => {
        dispatch(
          setCredentials({
            user: null,
            token: null,
          })
        );
        removeFromStorage(STORAGE_KEYS.token);

        navigate("/", { replace: true });
      }, 500);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const confirmLogout = () => {
    confirm({
      title: "Alert",
      content: "Are you sure you want to Logout?",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      centered: true,
      onOk() {
        handleLogout();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Profile</span>,
      icon: <UserOutlined />,
      onClick: () => navigate("/edit-profile"),
    },
    userData?.isPhoneVerify
      ? null
      : {
          key: "2",
          label: <span>Change Password</span>,
          icon: <KeyOutlined />,
          onClick: () => navigate("/change-password"),
        },
    {
      key: "3",
      label: <span>Documents</span>,
      icon: <UserOutlined />,
      onClick: () => navigate("/edit-documents"),
    },
    {
      key: "4",
      label: <span>Logout</span>,
      icon: <LogoutOutlined />,
      onClick: () => {
        confirmLogout();
      },
    },
  ];
  const { Header } = Layout;

  const getProfileData = async (token: any) => {
    const res = await getProfileMethod({}).unwrap();
    dispatch(
      setCredentials({
        user: res?.data || null,
        token: token || null,
      })
    );
  };

  const checkToken = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const data = getFromStorage(STORAGE_KEYS.userData);

    if (token) {
      dispatch(
        setCredentials({
          user: data ? JSON.parse(`${data}`) : null,
          token: JSON.parse(`${token}`) || null,
        })
      );
      getProfileData(JSON.parse(`${token}`));
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <Header style={{ padding: 0, height: 45 }}>
      <div className="hdr_mn"
        style={{
          background: "white",
          display: "flex",
          justifyContent: "space-between",
          height: "75px",
          padding: "10px 50px",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: 450,
            justifyContent: "flex-end",
            gap: "4%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/notifications")}
          >
            <BellOutlined style={{ fontSize: 25 }} />
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <img
              style={{
                height: 50,
                margin: "auto",
                borderRadius: "100%",
                width: 50,
              }}
              src={userData?.image || "/static/images/dummy.png"}
              alt="profile"
            />
          </div>
          <div
            onClick={(e) => e.preventDefault()}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Dropdown menu={{ items }}>
              <div>
                <div style={{ height: 20 }}>
                  {userData?.firstName
                    ? userData?.firstName + " " + userData?.lastName
                    : "Event"}
                  <CaretDownOutlined style={{ marginLeft: 10 }} />
                </div>
                <div>Event Provider</div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderBar;
