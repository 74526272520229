import React, { ReactNode, useState } from "react";
import { Layout } from "antd";
import SiderBar from "./sidebar";
import HeaderBar from "./header";

type props = {
  children: ReactNode;
};

const MainLayout = ({ children }: props) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout>
      <SiderBar collapsed={collapsed} />
      <Layout className="site-layout">
        <HeaderBar collapsed={collapsed} setCollapsed={setCollapsed} />
        {children}
      </Layout>
    </Layout>
  );
};

export default MainLayout;
