import { Button, Card, Col, Input, Modal, Row, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader } from "../../components";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useDeleteFundsMutation,
  useLazyGetAllFundsQuery,
} from "../../services/fundRaising";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../helpers";
import { Events } from "../../types/General";
import { time } from "console";
import moment from "moment";

const { confirm } = Modal;

interface DataType {
  key: string;
  eventName: string;
  moneyGoal: string;
  eventCategory: string;
  eventDateTime: string;
  status: boolean;
}

const FundEvents = () => {
  const navigate = useNavigate();

  const [fundsData, setFundsData] = useState<Events[]>();
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [deleteEventMethod] = useDeleteFundsMutation();

  const [getFundsEventsMethod] = useLazyGetAllFundsQuery();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllFundsData(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      const res = await deleteEventMethod({ fundId: id }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        successToast("Event deleted successfully");
        getAllFundsData(currentPage);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this event?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        handleDelete(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<Events> = [
    {
      title: "Event Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Money Goal",
      dataIndex: "moneyGoal",
      key: "moneyGoal",
      align: "center",
    },
    {
      title: "Event Date Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      render: (_, { time }) => (
        <div>
          <span>{moment(time).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-fund-event/details/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-fund-event/${record?._id}`);
            }}
          >
            <EditFilled style={{ color: "#C69320" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record?._id);
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      eventName: "Meeting",
      moneyGoal: "John Charles",
      eventCategory: "Music",
      eventDateTime: "20 Sep, 10:00 pm",
      status: true,
    },
    {
      key: "2",
      eventName: "Meeting",
      moneyGoal: "Emma Watson",
      eventCategory: "Movies",
      eventDateTime: "20 Jan,2:00 am",
      status: false,
    },
    {
      key: "3",
      eventName: "Meeting",
      moneyGoal: "Ria",
      eventCategory: "Games",
      eventDateTime: "10 May,12:00 pm",
      status: true,
    },
  ];

  const getAllFundsData = async (page: number) => {
    try {
      setLoading(true);
      const res = await getFundsEventsMethod({
        page,
        query,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setFundsData(res?.data?.fundRaising || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getAllFundsData(1);
  }, [query]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn mngfundrsng_mn">
      <ContentHeader
        heading="Manage Fundraising Events"
        buttonNavigateTo="manage-fund-event/add"
        buttonText="Add Events"
      />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Input
                placeholder="Search Events"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
              />
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <Button
                icon={<DownloadOutlined />}
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
              >
                Import CSV
              </Button> */}
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={fundsData}
            loading={loading}
            rowKey={"_id"}
            pagination={pagination}
          />
        </Card>
      </div>
    </div>
  );
};

export default FundEvents;
