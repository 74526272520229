import { Card, Col, Form, Input, Row } from "antd";
import { Dispatch, SetStateAction } from "react";

interface values {
  fbLink: string;
  instaLink: string;
  twitterLink: string;
}

interface props {
  setLinksValues: Dispatch<SetStateAction<values>>;
  linksValues: values;
}

const SocialLinkForm = ({ setLinksValues, linksValues }: props) => {
  return (
    <Card>
      <h3>Links to social media</h3>
      <div>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="facebookLink"
              rules={[
                {
                  pattern:
                    /^(https?:\/\/)?(www\.)?(facebook\.com)\/[a-zA-Z0-9_\-]+\/?$/i,
                  message: "Please enter valid link",
                },
              ]}
            >
              <div>
                <h4>Facebook Link</h4>
                <Input
                  value={linksValues.fbLink}
                  onChange={(e) =>
                    setLinksValues({ ...linksValues, fbLink: e.target.value })
                  }
                  placeholder="Facebook Link"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="instaLink"
              rules={[
                {
                  pattern:
                    /^(https?:\/\/)?(www\.)?(instagram\.com)\/[a-zA-Z0-9_\-]+\/?$/i,
                  message: "Please enter valid link",
                },
              ]}
            >
              <div>
                <h4>Instagram Link</h4>
                <Input
                  value={linksValues.instaLink}
                  onChange={(e) =>
                    setLinksValues({
                      ...linksValues,
                      instaLink: e.target.value,
                    })
                  }
                  placeholder="Instagram Link"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="twitterLink"
              rules={[
                {
                  pattern:
                    /^(https?:\/\/)?(www\.)?(twitter\.com)\/[a-zA-Z0-9_\-]+\/?$/i,
                  message: "Please enter valid link",
                },
              ]}
            >
              <div>
                <h4>Twitter Link</h4>
                <Input
                  value={linksValues.twitterLink}
                  onChange={(e) =>
                    setLinksValues({
                      ...linksValues,
                      twitterLink: e.target.value,
                    })
                  }
                  placeholder="Twitter Link"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default SocialLinkForm;
