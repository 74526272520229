import { Button, Card, Col, Form, Input, Row, Select, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ContentHeader } from "../../../components";
import EditText from "../../../components/textEditor";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { errorToast, successToast } from "../../../helpers";
import {
  useAddFundsMutation,
  useEditFundsMutation,
  useLazyGetFundByIdQuery,
} from "../../../services/fundRaising";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody, Events } from "../../../types/General";
import { useLazyGetAllEventsQuery } from "../../../services/events";

const { Option } = Select;

const FundEventForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const { view } = useParams();
  const [donationGoals, setDonationGoals] = useState(view === "add" ? " " : "");

  const [values, setValues] = useState({
    moneyGoal: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<string>();
  const [eventsList, setEventsList] = useState<Events[]>([]);

  const [addEventsMethod] = useAddFundsMutation();
  const [getDataMethod] = useLazyGetFundByIdQuery();
  const [editEventMethod] = useEditFundsMutation();
  const [getEventsMethod] = useLazyGetAllEventsQuery();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);

    setLoading(true);
    let data = {
      detail: donationGoals,
      moneyGoal: Number(values.moneyGoal),

      eventId: selectedEvent,
    };
    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      let res;
      view === "add"
        ? (res = await addEventsMethod(encryptedBody).unwrap())
        : (res = await editEventMethod({
            body: encryptedBody,
            fundId: view || "",
          }).unwrap());
      setLoading(false);
      if (res?.statusCode === 200) {
        successToast(
          view === "add"
            ? "Event added successfully"
            : "Event updated successfully"
        );
        navigate("/manage-fund-event", { replace: true });
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const getFundsData = async () => {
    try {
      setLoading(true);
      if (view) {
        const res = await getDataMethod({ fundId: view }).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          setValues({
            ...values,
            moneyGoal: res?.data?.moneyGoal,
          });
          setDonationGoals(res?.data?.detail);
          form.setFieldsValue({
            moneyGoal: res?.data?.moneyGoal,
            eventName: res?.data?.eventId?._id,
            donationGoals: res?.data?.detail,
          });
          setSelectedEvent(res?.data?.eventId?._id);
        }
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const getAllEventsData = async () => {
    try {
      setLoading(true);
      const res = await getEventsMethod({
        page: 1,
        query: "",
        activeTab: 4,
        size: 50,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setEventsList(res?.data?.event || []);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getAllEventsData();
    if (view !== "add") {
      getFundsData();
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      donationGoals: donationGoals,
      eventName: selectedEvent,
    });
  }, [donationGoals, selectedEvent]);

  return (
    <div style={{ padding: 50 }} className="evtsdtl_mn adfndrsng">
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manage-fund-event"
        heading={
          view === "add" ? "Add Fundraising Event" : "Edit Fundraising Event"
        }
      />
      <Spin spinning={loading}>
        <Card>
          <div>
            <Form
              name="basic"
              layout="vertical"
              form={form}
              style={{
                maxWidth: "100%",
                marginTop: 10,
              }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <h3>Event Details</h3>

              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="eventName"
                    rules={[
                      {
                        required: true,
                        message: "Please select event category!",
                      },
                    ]}
                  >
                    {/* <div> */}
                    <h4>Select Event</h4>
                    <Select
                      allowClear
                      maxLength={20}
                      className={
                        error && !selectedEvent ? "select_type2" : "select_type"
                      }
                      onChange={(val) => setSelectedEvent(val)}
                      value={selectedEvent}
                      style={{ width: "100%" }}
                      placeholder="Select Event"
                    >
                      {eventsList?.map((item, index) => (
                        <Option
                          key={index}
                          value={item?._id}
                          label={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                    {/* </div> */}
                    {/* {error && !selectedEvent ? (
                      <h3 className="error_msg">This field is required </h3>
                    ) : null} */}
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    name="moneyGoal"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <div>
                      <h4>Set Money Goal</h4>
                      <Input
                        placeholder="Set Money Goal"
                        style={{ height: 55 }}
                        type="number"
                        value={values.moneyGoal}
                        onChange={(e) =>
                          setValues({ ...values, moneyGoal: e.target.value })
                        }
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="donationGoals"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                      {
                        min: 9,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <h4>Donation Goals</h4>
                    {donationGoals ? (
                      <EditText
                        content={donationGoals}
                        setContent={setDonationGoals}
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <div style={{ display: "flex" }}>
                  <Button
                    style={{
                      background: "#ECAC35",
                      width: 200,
                      height: 50,
                      margin: "auto",
                      color: "black",
                    }}
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      setError(true);
                    }}
                  >
                    {view === "add" ? "Add" : "Update"}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default FundEventForm;
