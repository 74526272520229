import { END_POINTS } from "../helpers";
import { CommonBody, EventCategory, Events } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: {
    count: number;
    event: Events[] | [];
  };
  message: string;
};

type EventCategoryResponse = {
  statusCode: number;
  data?: { eventCategory: EventCategory[] };
  message: string;
};

type EditParams = {
  body: CommonBody;
  eventId: string;
};

export const eventApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addEvents: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.events,
        method: "POST",
        body,
      }),
    }),

    editEvents: builder.mutation<CommonResponseType, EditParams>({
      query: ({ body, eventId }) => ({
        url: `${END_POINTS.events}/${eventId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteEvents: builder.mutation<CommonResponseType, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `${END_POINTS.events}/${eventId}`,
        method: "DELETE",
      }),
    }),

    getAllEvents: builder.query<
      CommonResponseType,
      {
        page: number;
        query: string;
        activeTab?: number | undefined;
        size: number;
      }
    >({
      query: ({ page, query, activeTab, size }) => ({
        url: `${END_POINTS.events}?page=${page}&size=${size}${
          activeTab ? `&type=${activeTab}` : ""
        }&search=${query}&all=false`,
        method: "GET",
      }),
    }),

    getEventById: builder.query<
      CommonResponseType & { data: Events },
      { eventId: string }
    >({
      query: ({ eventId }) => ({
        url: `${END_POINTS.events}/${eventId}`,
        method: "GET",
      }),
    }),

    getEventsCategory: builder.query<EventCategoryResponse, {}>({
      query: () => ({
        url: END_POINTS.eventCategory + "?size=50&page=1",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddEventsMutation,
  useEditEventsMutation,
  useLazyGetAllEventsQuery,
  useLazyGetEventByIdQuery,
  useDeleteEventsMutation,
  useLazyGetEventsCategoryQuery,
} = eventApi;
