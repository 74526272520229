import { Button, Card, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorToast } from "../../../helpers";
import { useLazyGetEmployeeByIdQuery } from "../../../services/employees";

const UserDetails = () => {
  const navigate = useNavigate();
  const [EmployeeDataById] = useLazyGetEmployeeByIdQuery();
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState<any>();
  const { id } = useParams();

  const getEmployeeData = async () => {
    try {
      setLoading(true);
      const res = await EmployeeDataById({ employeeId: id }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setEmployeeData(res?.data);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }} className="evtsdtl_mn mngusrdetails">
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap:"10px"
            }}
          >
            <h2>Employee details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/manage-employee", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Profile image</h4>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      height: 100,
                      // margin: "auto",
                      width: 100,
                      borderRadius: 100,
                    }}
                    src={employeeData?.image || "/static/images/profile.png"}
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>First Name</h4>
                <h5>{employeeData?.firstName || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Last Name</h4>
                <h5>{employeeData?.lastName || "-"}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Email</h4>
                <h5>{employeeData?.email || "-"}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Phone Number</h4>
                <h5>
                  {employeeData?.phone
                    ? employeeData?.countryCode + " " + employeeData?.phone
                    : "-"}
                </h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Address</h4>
                <h5>{employeeData?.address || "-"}</h5>
              </Col>
            </Row>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default UserDetails;
