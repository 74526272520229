import { Breadcrumb, Button, Card } from "antd";
import { useNavigate } from "react-router-dom";

type ContentHeaderType = {
  heading: string;
  buttonText?: string;
  buttonNavigateTo?: string;
  bread?: boolean | undefined;
};

const ContentHeader = ({
  heading,
  buttonText,
  buttonNavigateTo,
  bread,
}: ContentHeaderType) => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          margin: "15px 0px",
        }}
      >
        {bread ? null : (
          <Breadcrumb
            style={{}}
            items={[
              {
                title: (
                  <div
                    className="breadcrumb_item"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </div>
                ),
              },
              {
                title: <div className="breadcrumb_item">{heading}</div>,
              },
            ]}
          />
        )}
      </div>

      <Card className="content_header">
        <div className="gap_prprty"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            gap:"10px",
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: 600,
              // height: 50,
            }}
          >
            {heading}
          </div>
          {buttonText ? (
            <Button
              style={{
                background: "#ECAC35",
                minWidth: 100,
                height: 35,
                color: "black",
                fontWeight: 500,
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                if (buttonNavigateTo) {
                  navigate(`/${buttonNavigateTo}`);
                } else {
                  navigate("/dashboard");
                }
              }}
            >
              {buttonText || "Add"}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ContentHeader;
